import { RefObject, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Button } from "@mui/material";
import { useAtomValue } from "jotai";

import { Currency } from "@sellernote/_shared/src/types/common/common";
import { AccountPaymentCondition } from "@sellernote/_shared/src/types/forwarding/adminAccount";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  TrelloBidDetail,
  WithdrawalFormDetail,
  WithdrawalFormListData,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { changePurchaseDomainToTemplateCategory } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import TemplateSearch from "../../../../../../../containers/TemplateSearch";
import useSnackbar from "../../../../../../../hooks/useSnackbar";
import useGetWithdrawalData from "./hooks/useGetWithdrawalData";
import useHandleWithdrawalInvoice from "./hooks/useHandleWithdrawalInvoice";
import useHandleWithdrawalRequest from "./hooks/useHandleWithdrawalRequest";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../../../jotaiStates/auth";
import CancelWithdrawModal from "./CancelWithdrawModal";
import CurrencyFilter from "./CurrencyFilter";
import PurchaseRequestForm from "./PurchaseRequestForm";
import WithdrawalInvoice from "./WithdrawalInvoice";

const ONE_AND_HALF_MILLION = 1500000;

const FormSection = ({
  shipmentId,
  companyType,
  /** TODO: 매입관리 고도화 기능 재개시 사용 예정 */
  partnerName,
  partnerId,
  exchangeRateList,
  bidAccountPayableId,
  trelloDetailData,
  accountId,
  accountPayment,
  onAccountIdChange,
  currencyApplyFunctionRef,
}: {
  shipmentId: number;
  companyType: PartnerBusinessArea;
  partnerName: string;
  partnerId: number;
  exchangeRateList: ExchangeRate[];
  bidAccountPayableId: number;
  trelloDetailData: TrelloBidDetail;
  accountId: number | undefined;
  accountPayment: AccountPaymentCondition | undefined;
  onAccountIdChange: (id: number | null) => void;
  currencyApplyFunctionRef: RefObject<{
    applyCurrency: (withdrawalFormList: WithdrawalFormDetail[]) => void;
  }>;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const userAuth = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const { watch: withdrawalListWatch, reset: withdrawalListReset } =
    useFormContext<WithdrawalFormListData>();

  const withdrawalFormList = withdrawalListWatch("withdrawalFormList");

  const { watch, reset, setValue } = useFormContext<ApplyBidFormData>();

  const [currency, setCurrency] = useState<Currency>("USD");
  // 직접 입력하거나 갱신 등으로 변경하는 환율 상태값
  const [currentExchangeRate, setCurrentExchangeRate] = useState(0);
  // currentExchangeRate와 비교해 수정 및 갱신이 있었는지 확인하기 위한 환율 상태값
  const [baseExchangeRate, setBaseExchangeRate] = useState(0);
  const [showCancelWithdrawModal, setShowCancelWithdrawModal] = useState(false);
  /** TODO: 매입관리 고도화 기능 재개시 기능 추가 예정 */
  const [showsInstantWithdrawalModal, setShowsInstantWithdrawalModal] =
    useState(false);

  const {
    withdrawalInvoiceId,
    handleWithdrawalInvoiceIdChange,
    withdrawalInvoiceDate,
    handleWithdrawalInvoiceDateChange,
    resetWithdrawalInvoiceIdAndDate,
  } = useHandleWithdrawalInvoice();

  const { isWithdrawalRequested, withdrawalItemList } = useGetWithdrawalData({
    bidAccountPayableId,
    setCurrency,
    setCurrentExchangeRate,
    setBaseExchangeRate,
    handleWithdrawalInvoiceIdChange,
    handleWithdrawalInvoiceDateChange,
    exchangeRateList,
    reset: withdrawalListReset,
    onAccountIdChange,
  });

  const {
    handleWithdrawalRequestClick,
    isWithdrawalOngoing,
    ResponseHandlerOfRequestWithdrawal,
  } = useHandleWithdrawalRequest({
    shipmentId,
    partnerId,
    bidAccountPayableId,
    withdrawalInvoiceId,
    accountId,
    currency,
    currentExchangeRate,
    companyType,
    withdrawalInvoiceDate,
    withdrawalFormList,
    resetWithdrawalInvoiceIdAndDate,
  });

  const validateWithdrawalRequest = () => {
    /** 입력 환율이 기준 환율과 다르다면 요청할 수 없음 */
    if (currentExchangeRate !== baseExchangeRate) {
      handleSnackbarOpen("변경된 환율이 적용되지 않았습니다.", "error");
      return false;
    }

    /** 세관을 제외한 파트너는 인보이스 날짜를 필수로 받음 */
    if (companyType !== "customsDuty" && !withdrawalInvoiceDate) {
      handleSnackbarOpen("인보이스 날짜를 입력해주세요.", "error");
      return false;
    }

    return true;
  };

  const sumOfFinalPrice = (() => {
    if (!withdrawalFormList?.length) return 0;

    return withdrawalFormList.reduce((acc, cur) => {
      acc += cur["finalPrice"];
      return acc;
    }, 0);
  })();

  /**
   * 즉시 출금 모달이 뜨는 조건
   *  1. 거래처의 지급 조건이 '일 정산'이어야 함
   *  2. 매입 대상이 세관 OR 출금 총 금액이 150만원보다 적어야 함
   *
   * TODO: 매입관리 고도화 기능 재개시 기능 추가 예정
   */
  const opensInstantWithdrawalModal =
    accountPayment === "daily" &&
    (companyType === "customsDuty" || sumOfFinalPrice < ONE_AND_HALF_MILLION);

  /** 템플릿 적용 시 현재 환율과 기준환율도 화폐에 맞는 환율로 변경해준다. */
  const changeWithdrawalCurrencyAfterTemplateUpdate = (currency: Currency) => {
    const templateCurrencyExchangeRate =
      exchangeRateList.find((exchangeRate) => {
        return exchangeRate.currency === currency;
      })?.rate || 0;

    setCurrency(currency);
    setCurrentExchangeRate(templateCurrencyExchangeRate);
    setBaseExchangeRate(templateCurrencyExchangeRate);
  };

  return (
    <Box width={1000}>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <TemplateSearch
          bidId={shipmentId}
          templateCategory={changePurchaseDomainToTemplateCategory(companyType)}
          reset={reset}
          watch={watch}
          setValue={setValue}
          templateFeeDataType={"withdrawalFormList"}
          templateType="withdrawal"
          bidAccountPayableId={bidAccountPayableId}
          exchangeRateList={exchangeRateList}
          changeWithdrawalCurrencyAfterTemplateUpdate={
            changeWithdrawalCurrencyAfterTemplateUpdate
          }
        />

        <Box display={"flex"} justifyContent={"space-between"}>
          <WithdrawalInvoice
            withdrawalInvoiceDate={withdrawalInvoiceDate}
            onWithdrawalInvoiceDateChange={handleWithdrawalInvoiceDateChange}
            withdrawalInvoiceId={withdrawalInvoiceId}
            onWithdrawalInvoiceIdChange={handleWithdrawalInvoiceIdChange}
          />

          <CurrencyFilter
            currency={currency}
            exchangeRateList={exchangeRateList}
            setCurrency={setCurrency}
            currentExchangeRate={currentExchangeRate}
            setCurrentExchangeRate={setCurrentExchangeRate}
            withdrawalFormList={withdrawalFormList}
            companyType={companyType}
            setBaseExchangeRate={setBaseExchangeRate}
            currencyApplyFunctionRef={currencyApplyFunctionRef}
          />
        </Box>
      </Box>

      <PurchaseRequestForm
        withdrawalFormList={withdrawalFormList}
        currency={currency}
        companyType={companyType}
        exchangeRateList={exchangeRateList}
        withdrawalItemList={withdrawalItemList}
        trelloDetailData={trelloDetailData}
        currentExchangeRate={currentExchangeRate}
      />

      <Box display="flex" justifyContent={"center"} mt={2}>
        <Box>
          <Button
            variant="outlined"
            disabled={isWithdrawalRequested || isWithdrawalOngoing}
            sx={{ mr: 1 }}
            onClick={() => {
              if (!validateWithdrawalRequest()) return;

              handleWithdrawalRequestClick({ requestWithdrawalFlag: false });
            }}
          >
            저장
          </Button>

          {isWithdrawalRequested ? (
            <Button
              variant="outlined"
              color="error"
              disabled={userAuth?.authority === "finance"}
              onClick={() => setShowCancelWithdrawModal(true)}
            >
              취소 요청
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              disabled={
                isWithdrawalOngoing ||
                // 해외파트너일 때는 withdrawal 데이터 중 하나라도 입력이 없으면 요청 금지
                (companyType === "foreign" &&
                  (!withdrawalInvoiceDate || !withdrawalInvoiceId))
              }
              onClick={() => {
                if (!validateWithdrawalRequest()) return;

                /**
                 * 즉시 출금 조건에 부합하는 경우 모달을 엶
                 *
                 * TODO: 매입관리 고도화 기능 재개시 기능 추가 예정
                 */
                // if (opensInstantWithdrawalModal) {
                //   setShowsInstantWithdrawalModal(true);
                //   return;
                // }

                /** 즉시 출금 조건에 부합하지 않는 경우 출금 요청 */
                handleWithdrawalRequestClick({ requestWithdrawalFlag: true });
              }}
            >
              저장 & 출금요청
            </Button>
          )}
        </Box>
      </Box>

      <CancelWithdrawModal
        isOpen={showCancelWithdrawModal}
        shipmentId={shipmentId}
        partnerId={partnerId}
        setShowCancelWithdrawModal={setShowCancelWithdrawModal}
      />

      {ResponseHandlerOfRequestWithdrawal}

      {/**
       * 즉시 출금 모달
       *
       * TODO: 매입관리 고도화 기능 재개시 기능 추가 예정
       */}
      {/* <InstantWithdrawalModal
        opensModal={showsInstantWithdrawalModal}
        onModalClose={() => setShowsInstantWithdrawalModal(false)}
        partnerName={partnerName}
        price={sumOfFinalPrice}
        onWithdrawalRequestClick={handleWithdrawalRequestClick}
      /> */}
    </Box>
  );
};

export default FormSection;
