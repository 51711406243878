import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import { InfographicTransferDate, InfographicTransferShipment } from "../types";

/**
 * 시간 데이터를 생성하는 함수
 *
 * 얼마나 걸리는지 날짜를 가공하는 데이터는 remainedDays 값만 삽입
 */
function createTimeTaken(remainedDays: number): InfographicTransferShipment {
  return {
    shipName: "",
    voyageNumber: "",
    ATA: null,
    ETA: null,
    ATD: null,
    ETD: null,
    port: {
      code: "",
      id: 0,
      name: "",
      nameEN: "",
    },
    transportType: "sea",
    remainedDays,
  };
}

/**
 * 출발지-환적지-도착지 각 선적 일정을 YYYY-MM-DD 포맷으로 리턴
 *
 * 전부다 없는 경우 문자열 '' 리턴
 *
 * @example
 * 우선순위
 * 1. ATA
 * 2. ETA
 * 3. ATD
 * 4. ETD
 */
function getTransferShipmentDate({
  ATA,
  ETA,
  ATD,
  ETD,
}: InfographicTransferDate): string {
  // TODO: toFormattedDate > 날짜 연산에 사용되는 포맷은 수정 보류
  return (
    toFormattedDate(ATA, "YYYY-MM-DD") ||
    toFormattedDate(ETA, "YYYY-MM-DD") ||
    toFormattedDate(ATD, "YYYY-MM-DD") ||
    toFormattedDate(ETD, "YYYY-MM-DD") ||
    ""
  );
}

/**
 * 쉽다 KR에서 YY.MM.DD 형식으로 포매팅된 날짜를 계산을 위해 2025-02-09 형식으로 변환 (dayjs에서 YY.MM.DD 타입은 날짜 계산을 할 수 없음)
 *
 * "YY. MM. DD." → "YYYY-MM-DD"
 */
const convertToCalculableDate = (date: string): string => {
  // 쉽다 영문의 경우 그대로 노출시킴
  if (ShipdaCurrentLanguage.currentLanguage === "en") return date;

  // FIXME: 고정으로 '20'이라는 문자열을 넣어주고 있어서 수정필요
  return date
    ? `20${date
        .split(".")
        .slice(0, 3)
        .map((s) => s.trim().padStart(2, "0"))
        .join("-")}`
    : "";
};

export { createTimeTaken, getTransferShipmentDate, convertToCalculableDate };
