import { useQueryClient } from "react-query";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import { WithdrawalFormDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../../../../hooks/useSnackbar";

export default function useHandleWithdrawalRequest({
  shipmentId,
  partnerId,
  bidAccountPayableId,
  withdrawalInvoiceId,
  accountId,
  currency,
  currentExchangeRate,
  companyType,
  withdrawalInvoiceDate,
  withdrawalFormList,
  resetWithdrawalInvoiceIdAndDate,
}: {
  shipmentId: number;
  partnerId: number;
  bidAccountPayableId: number;
  withdrawalInvoiceId: string;
  accountId: number | undefined;
  currency: Currency;
  currentExchangeRate: number;
  companyType: PartnerBusinessArea;
  withdrawalInvoiceDate: string | null;
  withdrawalFormList: WithdrawalFormDetail[];
  resetWithdrawalInvoiceIdAndDate: () => void;
}) {
  const queryClient = useQueryClient();
  const { handleSnackbarOpen } = useSnackbar();

  const {
    mutate: requestWithdrawal,
    isLoading: isWithdrawalOngoing,
    ResponseHandler: ResponseHandlerOfRequestWithdrawal,
  } = TRELLO_BID_QUERY.useRequestWithdrawal();

  const isOnlyKRW = (() => {
    return withdrawalFormList?.every(({ currency }) => currency === "KRW");
  })();

  const handleWithdrawalRequestClick = ({
    requestWithdrawalFlag,
    onSuccessCallback,
  }: {
    requestWithdrawalFlag: boolean;
    onSuccessCallback?: () => void;
  }) => {
    const detailList = withdrawalFormList.map((v: WithdrawalFormDetail) => {
      return {
        name: v.name || "",
        currency: v.currency,
        unitPrice: v.unitPrice,
        itemUnitMeasurement: v.itemUnitMeasurement,
        amount: v.amount,
        totalPrice: v.totalPrice,
        vatPrice: v.vatPrice,
        finalPrice: v.finalPrice,
        note: v.note,
        isVAT: v.isVAT,
      };
    });

    //여러개인 각 항목들의 가격들을 합친다.
    const totals = withdrawalFormList.reduce(
      (acc, v) => {
        acc.totalPrice += v.totalPrice;
        acc.finalPrice += v.finalPrice;
        acc.vatPrice += v.vatPrice;
        if (v.currency !== "KRW") {
          acc.foreignFinalPrice += v.unitPrice * v.amount;
        }
        return acc;
      },
      {
        totalPrice: 0,
        finalPrice: 0,
        vatPrice: 0,
        foreignFinalPrice: 0,
      }
    );

    const { totalPrice, finalPrice, vatPrice, foreignFinalPrice } = totals;

    requestWithdrawal(
      {
        detailList,
        bidId: shipmentId,
        companyType,
        requestWithdrawalFlag,
        foreignFinalPrice: isOnlyKRW ? 0 : Number(foreignFinalPrice.toFixed(2)),
        currency: isOnlyKRW ? "KRW" : currency,
        exchangeRate: isOnlyKRW ? 0 : currentExchangeRate,
        totalPrice:
          companyType === "foreign"
            ? Number(finalPrice.toFixed(0))
            : Number(totalPrice.toFixed(0)),
        vatPrice: companyType === "foreign" ? 0 : Number(vatPrice.toFixed(0)),
        finalPrice: Number(finalPrice.toFixed(0)),
        companyId: partnerId,
        bidAccountPayableId,
        withdrawalInvoiceId,
        ...(withdrawalInvoiceDate && { withdrawalInvoiceDate }),
      },
      {
        onSuccess: () => {
          handleSnackbarOpen(
            requestWithdrawalFlag
              ? "출금을 요청했습니다."
              : "출금을 저장했습니다."
          );

          resetWithdrawalInvoiceIdAndDate();
          onSuccessCallback?.();
          queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.all());
        },

        onError: ({ response }) => {
          if (response?.data.errorCode === "E074") {
            handleSnackbarOpen("인보이스 파일을 업로드해주세요.", "error");
            return;
          }

          if (response?.data.errorCode === "E075") {
            handleSnackbarOpen("이미 요청한 상태입니다.", "error");
            return;
          }

          handleSnackbarOpen("요청에 실패하셨습니다.", "error");
          return;
        },
      }
    );
  };

  return {
    handleWithdrawalRequestClick,
    isWithdrawalOngoing,
    ResponseHandlerOfRequestWithdrawal,
  };
}
